import React, { useEffect, useState } from 'react';
import { API, Auth } from 'aws-amplify';
import { Button } from 'react-bootstrap';
import { RedocStandalone } from 'redoc';
import useAuthentication from './hooks/useAuthentication';
import Header from './components/Header';
import { swagger } from './swagger';

function App() {
  const [isGetDocsSucceeded, setIsGetDocsSucceeded] = useState(false);
  const { isAuthenticated } = useAuthentication();
  useEffect(() => {
    const fetchAPI = async () => {
      if (isAuthenticated) {
        try {
          await API.get('getDocs', '/docs', {});
          setIsGetDocsSucceeded(true);
        } catch (error) {
          console.log({ error });
          setIsGetDocsSucceeded(false);
        }
      }
    };
    fetchAPI();
  }, [isAuthenticated]);

  const searchParams = new URLSearchParams(window.location.href.split('#')[1]);
  const error = searchParams.get('error');
  const error_description = searchParams.get('error_description');

  return (
    <div style={{ flex: 1, width: '100vw', height: '100vh' }}>
      <Header />

      {isAuthenticated || process.env.REACT_APP_LOCAL_START ? (
        isGetDocsSucceeded || process.env.REACT_APP_LOCAL_START ? (
          <>
            <RedocStandalone
              specUrl={swagger}
              options={{
                nativeScrollbars: true,
                theme: { colors: { primary: { main: '#dd5522' } } },
              }}
            />
          </>
        ) : (
          <p style={{ textAlign: 'center' }}>You are not allwed to read docs</p>
        )
      ) : (
        <div
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            height: '100vh',
            flexDirection: 'column',
          }}
        >
          <h4>Login to see API document</h4>
          <h5 style={{ color: 'red' }}>{`${error || ''}${error && error_description ? ' : ' : ''}${
            error_description || ''
          }`}</h5>
          <Button variant="primary" onClick={() => Auth.federatedSignIn()}>
            Login
          </Button>
        </div>
      )}
    </div>
  );
}

export default App;
