const backendSwagger = require('./swagger_backend.json');

export const swagger = {
  ...backendSwagger,
  paths: {
    ...backendSwagger.paths,
    ...require('./swagger_3DS.json').paths,
    ...require('./swagger_admin.json').paths,
    ...require('./swagger_webhook.json').paths,
    ...require('./swagger_subscription.json').paths,
  },
};
