const config = {
  apiGateway: {
    REGION: 'eu-central-1',
    URL: 'https://dev.api.dycama.com',
  },
  cognito: {
    REGION: 'eu-central-1',
    USER_POOL_ID: 'eu-central-1_tJlhHl5xy',
    APP_CLIENT_ID: '3v4r9ijvosfejkq9dtngrq4bpf',
    DOMAIN: 'rampaign-documentation.auth.eu-central-1.amazoncognito.com',
    SCOPE: ['email', 'openid'],
    REDIRECT_SIGN_IN: 'https://docs.dycama.com/',
    REDIRECT_SIGN_OUT: 'https://docs.dycama.com/',
    RESPONSE_TYPE: 'token',
  },
};
export default config;
