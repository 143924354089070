import { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';

export default function useAuthentication() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState({});
  useEffect(() => {
    const fetchUserSession = async () => {
      try {
        const userSession = await Auth.currentSession();
        if (!userSession) {
          setIsAuthenticated(false);
        } else {
          setIsAuthenticated(true);
        }
      } catch (error) {
        console.log({ error });
      }
    };
    fetchUserSession();
  }, []);

  useEffect(() => {
    const fetchUserInfo = async () => {
      const userInfo = await Auth.currentAuthenticatedUser();
      setUser(userInfo);
    };
    if (isAuthenticated) {
      fetchUserInfo();
    } else {
      setUser({});
    }
  }, [isAuthenticated]);

  return { isAuthenticated, user };
}
