import React from 'react';
import { Navbar, Nav, Button } from 'react-bootstrap';
import { Auth } from 'aws-amplify';
import useAuthentication from '../hooks/useAuthentication';

export default function Header() {
  const { isAuthenticated } = useAuthentication();

  return (
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
      <Navbar.Brand
        style={{
          height: 40,
          paddingTop: 0,
          paddingBottom: 0,
          marginTop: '0.3125rem',
          marginBottom: '0.3125rem',
        }}
      >
        <img src="/logo_dycama.png" alt="Dycama" height={40} />{' '}
        <a style={{ verticalAlign: 'bottom' }}>Docs</a>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav />
        <Nav>{isAuthenticated ? <Button onClick={() => Auth.signOut()}>Logout</Button> : null}</Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}
